<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="10"
        v-if="hasLoaded"
      )
        v-row.mb-6(
          align="center"
          justify="space-between"
        )
          v-col(
            cols="12"
            md="auto"
          )
            v-layout(
              align-center
            )
              h1.text-h4 {{ kit.title }}
              v-tooltip(
                top
              )
          v-col(
            cols="12"
            md="auto"
          )
            v-tooltip(
              top
            )
              template(#activator="{ on, attrs}")
                v-btn.px-0.mr-2(
                  v-on="on"
                  min-width="40px"
                  @click="deleteKit"
                  color="error"
                )
                  v-icon mdi-delete
              span Delete Kit
            v-btn(
              :to="{ name: 'edit-kit', params: { kitId: kit.id }}"
              color="primary"
            )
              v-icon(
                left
              ) mdi-pencil
              | Edit Kit

        v-card(
          outlined
          v-if="hasLoaded"
        )
          v-card-text
            v-row
              v-col
                v-text-field(
                  readonly
                  label="Title"
                  outlined
                  v-model="kit.title"
                )
                v-textarea(
                  readonly
                  filled
                  outlined
                  rows='4'
                  label="Description"
                  v-model="kit.description"
                )

          v-data-table.clickable(
            :headers="headers"
            no-data-text="No Parts Found"
            disable-pagination
            :items="parts"
            @click:part=""
            hide-default-footer
            :items-per-page="-1"
          )
            template(#item.listPrice="{ item: product }")
              span ${{ Number(product.listPrice).toFixed(2) | round(2) }}
            template(#item.costPrice="{ item: product }")
              span ${{ Number(product.costPrice).toFixed(2) | round(2) }}
</template>

<script>
import { useGet, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'Kit',
  props: {
    kitId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Kit } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return { query: { $mergeEager: 'shop' } }
    })

    // Get the patient record
    const { item: kit, hasLoaded } = useGet({
      params,
      model: Kit,
      id: props.kitId
    })
    const { KitPart } = context.root.$FeathersVuex.api

    const partsParams = computed(() => {
      const query = {
        query: {
          kit_id: props.kitId,
          $limit: 99
        }
      }

      return query
    })

    const { items: parts, isPending: arePartsPending } = useFind({
      model: KitPart,
      params: partsParams
    })

    return {
      parts,
      arePartsPending,
      kit,
      hasLoaded
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async deleteKit () {
      try {
        const message = 'Are you sure you want to delete this invoice?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        const currentKit = this.kit

        if (isYes) {
          await this.kit.remove()
          this.$snackSuccess('Parts Kit Deleted')
          this.$router.push({ name: 'shop', params: { shopId: currentKit.shop_id } })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Item',
        value: 'part'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'Cost Price',
        value: 'costPrice'
      },
      {
        text: 'List Price',
        value: 'listPrice'
      }
    ]
  })
}
</script>
