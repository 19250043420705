var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[(_vm.hasLoaded)?_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-row',{staticClass:"mb-6",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('h1',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.kit.title))]),_c('v-tooltip',{attrs:{"top":""}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"px-0 mr-2",attrs:{"min-width":"40px","color":"error"},on:{"click":_vm.deleteKit}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,2717385144)},[_c('span',[_vm._v("Delete Kit")])]),_c('v-btn',{attrs:{"to":{ name: 'edit-kit', params: { kitId: _vm.kit.id }},"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit Kit")],1)],1)],1),(_vm.hasLoaded)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","label":"Title","outlined":""},model:{value:(_vm.kit.title),callback:function ($$v) {_vm.$set(_vm.kit, "title", $$v)},expression:"kit.title"}}),_c('v-textarea',{attrs:{"readonly":"","filled":"","outlined":"","rows":"4","label":"Description"},model:{value:(_vm.kit.description),callback:function ($$v) {_vm.$set(_vm.kit, "description", $$v)},expression:"kit.description"}})],1)],1)],1),_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"no-data-text":"No Parts Found","disable-pagination":"","items":_vm.parts,"hide-default-footer":"","items-per-page":-1},on:{"click:part":function($event){}},scopedSlots:_vm._u([{key:"item.listPrice",fn:function(ref){
var product = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.listPrice).toFixed(2),2)))])]}},{key:"item.costPrice",fn:function(ref){
var product = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.costPrice).toFixed(2),2)))])]}}],null,false,3102303177)})],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }